import React, { useState } from 'react';

import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import axios from "axios"


const LemmatizerPage = (props) => {

    const [loading, setLoading] = useState(false);
    const [text, setText] = useState("");
    const [lemmas, setLemmas] = useState([]);
    const [error, setError] = useState(null);

    const fetchData = () => {
        setLoading(true);
        axios
            .get(`https://www.analiza-gramaticala.ro:4443/lemmatizer2/` + encodeURIComponent(text))
            .then(({ data }) => {
                console.log('lemmas', data);
                setLoading(false);
                setLemmas(data);
            })
            .catch(error => {
                setLoading(false);
                setError(error);
            });
    }

    return <Layout  location={props.location}>
        <SEO title="lemmatize some data"/>
        <h1>lemmatize</h1>
        <p>Welcome to lemmatize page</p>
        <input value={text} onChange={e => setText(e.target.value)}/>
        <input type={'button'} value={'Caută'} onClick={fetchData}/>

        <br/>
        <br/>

        {error && <div>error: {error}</div>}
        <p>
            {lemmas.map(l => <div>{l.lemma + " / " + l.desc}</div>)}
        </p>


        <p>
        <Link to="/">Go back to the homepage</Link>
        </p>
    </Layout>
}

export default LemmatizerPage
